import React from "react"

import { PageView } from "marketplace/app/components"
import Cart from "marketplace/cart"

const CONTENT = {
  seo: "Shopping Cart",
  title: "Checkout your data order and pay with MetaMask",
  description:
    "Remember that payments can only be made one at a time through Metamask.",
}

const CartPage = () => {
  return (
    <PageView
      seo={CONTENT.seo}
      title={CONTENT.title}
      description={CONTENT.description}
      styleName="black"
    >
      <Cart />
    </PageView>
  )
}

export default CartPage
